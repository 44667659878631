import { createContext, useReducer, useEffect, useState } from 'react'

export const ThemeContext = createContext('Light')

export const ThemeContextProvider = ({ children }) => {
  const [toggle, setToggle] = useState('Light')
  const toggleFunction = () => {
    localStorage.setItem('mode', toggle === 'Light' ? 'Dark' : 'Light')
    setToggle((prev) => (prev === 'Light' ? 'Dark' : 'Light'))
  }

  useEffect(() => {
    const mode = localStorage.getItem('mode')

    if (mode === 'Dark') {
      setToggle('Dark')
    } else {
      setToggle('Light')
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ toggle, toggleFunction }}>
      {children}
    </ThemeContext.Provider>
  )
}
