import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Background from '../../components/Background'
import NavBar from '../../components/NavBar'
import { useAuthContext } from '../../hooks/useAuthContext'
import {
  Box,
  Divider,
  FormLabel,
  IconButton,
  NativeSelect,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import SuspenseLoader from '../../components/SuspenseLoader'
import { ReactComponent as ArrowLeftIcon } from '../../resources/images/Left_Arrow_Dark_Mode.svg'
import { ReactComponent as ArrowLeftIconLightMode } from '../../resources/images/Left_Arrow.svg'
import { useNavigate } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { countries } from '../../data/data'
import ReactInputMask from 'react-input-mask'
import { LoadingButton } from '@mui/lab'
import { get, post } from '../../services/Api'
import { enqueueSnackbar } from 'notistack'
import containerBackground from '../../resources/images/Container_Background.png'
import containerBackgroundLightMode from '../../resources/images/Container_Background_Light_Mode_Alt.png'
import { useThemeContext } from '../../hooks/useThemeContext'

const initialValues = {
  countryCode: '1',
  name: '',
  email: '',
  phoneNumber: '',
  description: '',
}
const validationSchema = yup.object().shape(
  {
    phoneNumber: yup
      .string()
      .ensure()
      .when('email', {
        is: (email) => !email,
        then: (schema) =>
          schema.required('Email Address or Phone Number Required'),
        // schema.required('Email Address Required'),
        otherwise: (schema) => schema.optional(),
      }),
    email: yup
      .string()
      .email('Enter valid email address')
      .ensure()
      .when('phoneNumber', {
        is: (phoneNumber) => !phoneNumber,
        then: (schema) =>
          schema.required('Email Address or Phone Number Required'),
        // schema.required('Email Address Required'),
        otherwise: (schema) => schema.optional(),
      }),
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
  },
  [['email', 'phoneNumber']],
)

const ContactUs = () => {
  const theme = useTheme()
  const { toggle } = useThemeContext()
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState({
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  })

  const handleChange = (value) => {
    formik.setFieldValue('countryCode', value)
    setSelectedCountry(countries.find((country) => country.phone === value))
  }

  const handlePhoneNumberChange = (e) => {
    formik.setFieldValue('phoneNumber', e.target.value)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      if (data.phoneNumber) {
        data.phone = ('+' + data.countryCode + data.phoneNumber).replace(
          /-/g,
          '',
        )
        // enqueueSnackbar('This feature temporary unavailable, try again later', {
        //   variant: 'error',
        // })
        // return
        // delete data['countryCode']
        // delete data['phoneNumber']
        delete data['email']
      } else {
        delete data['countryCode']
        delete data['phoneNumber']
      }
      setIsLoading(true)
      // Api Call
      const response = await post('contact-us', data)
      setIsLoading(false)
      if (response && response.statusCode === 201) {
        // enqueueSnackbar('Thank you for contacting us!', {
        //   variant: 'success',
        // })
        formik.resetForm()
        navigate('/thanks-for-reaching-us')
        // setSelectedCountry({
        //   code: 'CA',
        //   label: 'Canada',
        //   phone: '1',
        //   suggested: true,
        // })
      }
    },
  })

  useEffect(() => {
    get('manage-count')
  }, [])
  return (
    <>
      <Helmet>
        <title>Contact Us | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      {/* <Background /> */}
      {user ? <NavBar /> : ''}
      <Box pb={{ xs: '25px', md: '58px' }} px="15px">
        <Stack
          sx={{
            borderRadius: '40px',
            border: ' 4px solid rgba(0, 0, 0, 0.07)',
            background: `url(${
              toggle === 'Dark'
                ? containerBackground
                : containerBackgroundLightMode
            })`,
            backgroundPosition: 'center',
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            boxShadow:
              toggle === 'Dark'
                ? '0px 0px 190px 0px rgba(0, 0, 0, 0.07)'
                : '0px 8px 20px 0px #0000001A',
            maxWidth: '640px',
            mx: 'auto',
            mt: { xs: '15px', md: '30px' },
            //   py: '30px',
            //   px: { sm: '30px' },
            padding: '30px',
          }}
        >
          <Stack position="relative" justifyContent="center">
            <IconButton
              sx={{
                position: 'absolute',
                // rotate: '90deg',
                top: '0',
                left: { xs: '15px', md: '0px' },
              }}
              onClick={() => navigate('/dashboard')}
            >
              {toggle === 'Dark' ? (
                <ArrowLeftIcon />
              ) : (
                <ArrowLeftIconLightMode />
              )}
              {/* <ExpandMore height="25px" width="25px"/> */}
            </IconButton>
            <Typography
              variant="h7"
              textAlign="center"
              px="50px"
              fontWeight={600}
            >
              Contact us
            </Typography>
            <Typography variant="h6" mt="20px" textAlign="center">
              Fill this out and we’ll reply in less than 24 hours!
            </Typography>
            <Box mt="30px">
              <form onSubmit={formik.handleSubmit}>
                <Box mb="10px">
                  <FormLabel
                    htmlFor="name"
                    sx={{
                      mb: '5px',
                    }}
                  >
                    Name*
                  </FormLabel>
                  <TextField
                    id="name"
                    fullWidth
                    type="text"
                    placeholder="Enter your full name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.errors.name && formik.touched.name}
                    helperText={
                      formik.errors.name &&
                      formik.touched.name &&
                      formik.errors.name
                    }
                  />
                </Box>
                <Box mb="10px">
                  <FormLabel
                    htmlFor="phoneNumber"
                    sx={{
                      mb: '5px',
                    }}
                  >
                    Phone number
                  </FormLabel>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="20px"
                    width="100%"
                    height="64px"
                    py="10px"
                    sx={{
                      backgroundColor:
                        toggle === 'Dark' ? '#111114' : '#F4F4F4',
                      borderRadius: '16px',
                      height: '64px',
                      border:
                        toggle === 'Dark'
                          ? '1.5px solid rgba(0, 0, 0, 0.06)'
                          : '1.5px solid #0000000F',
                      borderColor:
                        formik.errors.phoneNumber &&
                        formik.touched.phoneNumber &&
                        theme.palette.error.main,
                      //    ||
                      // (formik.values.email && 'rgba(145, 158, 171, 0.8)')
                      [theme.breakpoints.down(600)]: {
                        height: '50px',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: toggle === 'Dark' && '1px solid #111114',
                        backgroundColor:
                          toggle === 'Dark' ? '#111114' : '#F4F4F4',
                        width: '115px',
                        height: '100%',
                        display: 'flex',
                        justifyContent: { xs: 'center', lg: 'center' },
                        alignItems: 'center',
                        position: 'relative',
                        padding: { xs: '0px 8px', md: '0px 16px' },
                        gap: { xs: '6px', md: '8px' },
                        borderRight: '1.5px solid #7E809A',
                      }}
                      color={formik.values.email && theme.palette.grey[400]}
                    >
                      <Typography variant="h4" fontWeight={500}>
                        +{selectedCountry.phone}
                      </Typography>
                      <ExpandMore />
                      <NativeSelect
                        name="countryCode"
                        value={selectedCountry.countryCode}
                        fullWidth
                        defaultValue={selectedCountry.phone}
                        onChange={(e) => {
                          handleChange(e.target.value)
                          // formik.setFieldValue('countryCode', e.target.value)
                        }}
                        disabled={formik.values.email}
                        sx={{
                          height: '100%',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          opacity: 0,
                        }}
                      >
                        {countries
                          .sort((a, b) => -b.label[0].localeCompare(a.label[0]))
                          .map((country) => {
                            return (
                              <option key={country.code} value={country.phone}>
                                {country.label}
                              </option>
                            )
                          })}
                      </NativeSelect>
                    </Box>
                    <ReactInputMask
                      maskChar=""
                      mask="999-999-9999"
                      value={formik.values.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      disabled={formik.values.email}
                    >
                      {() => (
                        <TextField
                          id="phoneNumber"
                          variant="standard"
                          placeholder="000-000-0000"
                          name="phoneNumber"
                          fullWidth
                        />
                      )}
                    </ReactInputMask>
                  </Stack>
                </Box>
                <Stack
                  my="12px"
                  direction="row"
                  alignItems="center"
                  spacing="6px"
                >
                  <Divider
                    sx={{
                      width: 'calc(50% - 1ch - 6px)',
                      borderWidth: '1px',
                    }}
                  />
                  <Typography variant="h6" color={theme.palette.grey[900]}>
                    OR
                  </Typography>
                  <Divider
                    sx={{
                      width: 'calc(50% - 1ch - 6px)',
                      borderWidth: '1px',
                    }}
                  />
                </Stack>
                <Box mb="10px">
                  <FormLabel
                    htmlFor="email"
                    sx={{
                      mb: '5px',
                    }}
                  >
                    Email address
                  </FormLabel>
                  <TextField
                    id="email"
                    fullWidth
                    type="email"
                    placeholder="Enter your email "
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email && formik.touched.email}
                    helperText={
                      formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email
                    }
                    disabled={formik.values.phoneNumber}
                  />
                </Box>
                <Box>
                  <FormLabel
                    htmlFor="description"
                    sx={{
                      mb: '5px',
                    }}
                  >
                    What can we help you with? *
                  </FormLabel>
                  <TextField
                    id="description"
                    fullWidth
                    type="text"
                    placeholder="Write here"
                    name="description"
                    multiline
                    rows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.errors.description && formik.touched.description
                    }
                    helperText={
                      formik.errors.description &&
                      formik.touched.description &&
                      formik.errors.description
                    }
                    sx={{
                      '.MuiInputBase-root': {
                        height: 'auto',
                      },
                    }}
                  />
                </Box>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ mt: '30px' }}
                >
                  {!isLoading && 'SUBMIT'}
                </LoadingButton>
              </form>
            </Box>
          </Stack>
        </Stack>
        <Typography
          textAlign="center"
          mt="15px"
          fontSize={14}
          fontWeight={400}
          color="mode.darker"
        >
          ©2024 HappyBdayFriend{' '}
        </Typography>
      </Box>
    </>
  )
}

export default ContactUs
