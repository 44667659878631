import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { retry } from './common/utils/commonFunction'
import { useAuthContext } from './hooks/useAuthContext'
import { useAdminAuthContext } from './hooks/useAdminAuthContext'
import ContactUs from './pages/User/ContactUs'
import Faq from './pages/User/Faq'
import ThemeProvider from './theme'
// import RedirectionPath from './pages/RedirectionPath'
// import Success from './pages/User/Success'

const SignUp = lazy(() => retry(() => import('./pages/User/SignUp')))
const VerifyCode = lazy(() => retry(() => import('./pages/User/VerifyCode')))
const PersonalDetails = lazy(() =>
  retry(() => import('./pages/User/PersonalDetails')),
)
const FindUs = lazy(() => retry(() => import('./pages/User/FindUs')))
const Dashboard = lazy(() => retry(() => import('./pages/User/Dashboard')))
const CreateCelebration = lazy(() =>
  retry(() => import('./pages/User/CreateCelebration')),
)
const CelebrationDetails = lazy(() =>
  retry(() => import('./pages/User/CelebrationDetails')),
)
const Blogs = lazy(() => retry(() => import('./pages/User/Blogs')))
const Blog = lazy(() => retry(() => import('./pages/User/Blog')))
const SubscriptionPlans = lazy(() =>
  retry(() => import('./pages/User/SubscriptionPlans')),
)
const UploadVideo = lazy(() =>
  retry(() => import('./pages/Common/UploadVideo')),
)
const PrivacyPolicy = lazy(() =>
  retry(() => import('./pages/Common/PrivacyPolicy')),
)
const TermsOfService = lazy(() =>
  retry(() => import('./pages/Common/TermsOfService')),
)
const Support = lazy(() => retry(() => import('./pages/Common/Support')))
const SignIn = lazy(() => retry(() => import('./pages/Admin/SignIn')))
const AdminDashboard = lazy(() =>
  retry(() => import('./layouts/Admin/Dashboard')),
)
const FourZeroFour = lazy(() =>
  retry(() => import('./pages/Common/FourZeroFour')),
)
const ThankYouPage = lazy(() =>
  retry(() => import('./pages/Common/ThankYouPage')),
)
const Home = lazy(() => retry(() => import('./pages/Common/Home')))
const Success = lazy(() => retry(() => import('./pages/User/Success')))
const Failure = lazy(() => retry(() => import('./pages/User/Failure')))
const Profile = lazy(() => retry(() => import('./pages/User/Profile')))
const RedirectionPath = lazy(() =>
  retry(() => import('./pages/RedirectionPath')),
)

const Routes = () => {
  const { user } = useAuthContext()
  const { admin } = useAdminAuthContext()

  const route = useRoutes([
    // {
    //   path: '/',
    //   element: user ? <Dashboard /> : <Navigate replace to="/sign-up" />,
    // },
    {
      path: '/sign-up',
      element: user ? <Navigate replace to="/dashboard" /> : <SignUp />,
    },
    {
      path: '/verify-code',
      element: user ? <Navigate replace to="/dashboard" /> : <VerifyCode />,
    },
    {
      path: '/personal-details',
      element: user ? <PersonalDetails /> : <Navigate replace to="/sign-up" />,
    },
    {
      path: '/how-did-you-find-us',
      element: user ? <FindUs /> : <Navigate replace to="/sign-up" />,
    },
    // {
    //   path: '/create-celebration',
    //   element: user ? (
    //     <CreateCelebration />
    //   ) : (
    //     <Navigate replace to="/sign-up" />
    //   ),
    // },
    // {
    //   path: '/edit-celebration',
    //   element: user ? (
    //     <CreateCelebration />
    //   ) : (
    //     <Navigate replace to="/sign-up" />
    //   ),
    // },
    // {
    //   path: '/subscription-plans',
    //   element: user ? (
    //     <SubscriptionPlans />
    //   ) : (
    //     <Navigate replace to="/sign-up" />
    //   ),
    // },
    // {
    //   path: '/celebration-details/:id',
    //   element: user ? (
    //     <CelebrationDetails />
    //   ) : (
    //     <Navigate replace to="/sign-up" />
    //   ),
    // },
    {
      path: '/c/:id',
      element: <UploadVideo />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },

    {
      path: '/terms-of-service',
      element: <TermsOfService />,
    },
    {
      path: '/support',
      element: <Support />,
    },
    {
      path: '/admin/sign-in',
      element: admin ? (
        <Navigate replace to="/admin/" />
      ) : (
        <ThemeProvider>
          <SignIn />
        </ThemeProvider>
      ),
    },
    {
      path: '/admin/*',
      element: admin ? (
        <ThemeProvider>
          {' '}
          <AdminDashboard />
        </ThemeProvider>
      ) : (
        <Navigate replace to="/admin/sign-in" />
      ),
    },
    // {
    //   path: '/:name',
    //   element: <Support />,
    // },
    {
      path: '/404',
      element: <FourZeroFour />,
    },
    {
      path: '/contact-us',
      element: <ContactUs />,
    },
    {
      path: '/thanks-for-reaching-us',
      element: <ThankYouPage />,
    },
    {
      path: '/faq',
      element: <Faq />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/dashboard',
      element: user ? <Dashboard /> : <Navigate replace to="/sign-up" />,
    },

    {
      path: '/create-celebration',
      element: user ? (
        <CreateCelebration />
      ) : (
        <Navigate replace to="/sign-up" />
      ),
    },
    {
      path: '/edit-celebration',
      element: user ? (
        <CreateCelebration />
      ) : (
        <Navigate replace to="/sign-up" />
      ),
    },
    // {
    //   path: '/subscription-plans',
    //   element: user ? (
    //     <SubscriptionPlans />
    //   ) : (
    //     <Navigate replace to="/sign-up" />
    //   ),
    // },
    {
      path: '/celebration-details/:id',
      element: user ? (
        <CelebrationDetails />
      ) : (
        <Navigate replace to="/sign-up" />
      ),
    },
    {
      path: '/blogs',
      // element: user ? <Blogs /> : <Navigate replace to="/sign-up" />,
      element: <Blogs />,
    },
    {
      path: '/blog/:title',
      // element: user ? <Blog /> : <Navigate replace to="/sign-up" />,
      element: <Blog />,
    },
    {
      path: '/success',
      element: user ? <Success /> : <Navigate replace to="/sign-up" />,
    },
    {
      path: '/failure',
      element: user ? <Failure /> : <Navigate replace to="/sign-up" />,
    },
    {
      path: '/profile',
      element: user ? <Profile /> : <Navigate replace to="/sign-up" />,
    },
    {
      path: '/404',
      element: <FourZeroFour />,
    },
    {
      path: '/*',
      // element: <Navigate replace to="/404" />,
      element: <RedirectionPath />,
    },
  ])
  return route
}

export default Routes
