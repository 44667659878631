import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#F4F4F4',
  700: '#454F5B',
  800: '#A8A8A8',
  900: '#B3B3B3',
}
//changed
const PRIMARY = {
  // lighter: "#A2BDCB",
  // light: "#EEF8FD",
  main: '#8C7BFF',
  // dark: "#0099FF",
  // darker: "#11013D",
  contrastText: '#fff',
}

const YELLOW = {
  main: '#FED101',
  contrastText: '#999999',
}
const GREEN = {
  main: '#4ADA85',
  contrastText: '#999999',
}

const SECONDARY = {
  // lighter: '#D6E4FF',
  // light: '#84A9FF',
  main: '#FDA4FF',
  // dark: '#1939B7',
  // darker: '#091A7A',
  contrastText: '#fff',
}

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
}

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#1AB159',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
}

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
}

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FF8484',
  main: '#EA4807',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
}

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  yellow: YELLOW,
  green: GREEN,
  divider: alpha(GREY[500], 0.24),
  //changed
  text: {
    primary: '#1C2A4C',
    secondary: '#BBBBBB',
    disabled: '#FFFFFF',
  },
  background: {
    paper: '#fff',
    default: '#FFF',
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
