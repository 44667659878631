import { createContext, useReducer, useEffect } from 'react'

export const AdminAuthContext = createContext()

export const adminAuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { admin: action.payload }
    case 'LOGOUT':
      return { admin: null }
    default:
      return state
  }
}

export const AdminAuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminAuthReducer, {
    admin: localStorage.getItem('admin'),
  })

  useEffect(() => {
    const admin = localStorage.getItem('admin')

    if (admin) {
      dispatch({ type: 'LOGIN', payload: admin })
    } else {
      dispatch({ type: 'LOGOUT' })
    }
  }, [])

  return (
    <AdminAuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AdminAuthContext.Provider>
  )
}
