import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`

const setDefaultHeaders = () => {
  axios.defaults.headers.common = {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json; charset=utf-8',
  }

  const token = JSON.parse(localStorage.getItem('user'))

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token?.authentication?.accessToken}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

const successCodes = [200, 201, 204]

/**
 * Get request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const get = async (path, params, headers) => {
  try {
    setDefaultHeaders()

    return await axios.get(path, params, headers).then(getResponse)
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
    }
    if (error.response?.data) return showError(error.response?.data?.message)

    return showError(error.message)
  }
}

/**
 * Post request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const post = async (path, params, headers) => {
  setDefaultHeaders()
  const data = await axios
    .post(path, params || {}, headers)
    .then(getResponse)
    .catch((error) => {
      if (error.response?.data) return showError(error.response?.data?.message)

      return showError(error.message)
    })

  return data
}

/**
 * Axios PUT Request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const put = (path, params, headers) => {
  return new Promise((resolve, reject) => {
    try {
      setDefaultHeaders()
      axios
        .put(path, params || {}, headers)
        .then(getResponse)
        .then(resolve)
        .catch(reject)
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * Axios PATCH Request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const patch = (path, params, headers) => {
  return new Promise((resolve, reject) => {
    try {
      setDefaultHeaders()
      axios
        .patch(path, params || {}, { headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject)
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * Axios DELETE Request
 * @param {*} path
 * @param {*} params
 * @param {*} headers
 * @returns
 */
export const remove = async (path, params, headers) => {
  try {
    setDefaultHeaders()
    const data = await axios
      .delete(path, params || {}, headers)
      .then(getResponse)
    return data
  } catch (error) {
    if (error.response?.data) return showError(error.response?.data?.message)

    return showError(error.message)
  }
}

/**
 * Show error message
 * @param {string} message
 * @returns
 */
export const showError = (message) => {
  if (message === 'Unauthorized') {
    window.location.href = '/sign-up'
    localStorage.clear()

    enqueueSnackbar('Logged out, Please login again.', {
      variant: 'error',
    })
  } else {
    if (message === 'Network Error') {
      // window.location.href = '/sign-up'
      // localStorage.clear()

      enqueueSnackbar('Please check your network and try again!', {
        variant: 'error',
      })
    } else {
      // localStorage.clear()
      enqueueSnackbar(message, {
        variant: 'error',
      })
    }
  }
  return { data: false }
}

/**
 * Handle Axios response
 * @param res   HTTP Response
 * @returns     Return data
 */
export const getResponse = (res) => {
  if (res && successCodes.includes(res.status)) {
    if (res.status === 201 || res.status === 204) {
      return res.data
    }
    return res.data
  }

  return res.data
}
