import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import darkPalette from './darkPalette'
import lightPalette from './lightPalette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'
import { useThemeContext } from '../hooks/useThemeContext'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const { toggle } = useThemeContext()

  const themeOptions = useMemo(
    () => ({
      palette: toggle === 'Dark' ? darkPalette : lightPalette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [toggle],
  )

  const theme = createTheme(themeOptions)

  theme.components = {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          color: theme.palette.text.secondary,
          display: 'inline-block',
          marginBottom: '1px',
          [theme.breakpoints.down('md')]: {
            fontSize: '15px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: toggle === 'Dark' ? '#111114' : '#F4F4F4',
          borderRadius: '16px',
          // color: theme.palette.grey[900],
          color: theme.palette.mode.light,
          height: '64px',
          fontSize: 18,
          fontWeight: 400,
          borderWidth: 1.5,
          borderColor: toggle === 'Light' ? '#0000000F' : 'unset',
          [theme.breakpoints.down(600)]: {
            height: '50px',
            borderRadius: '10px',
            fontSize: 16,
          },
          ':hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
              borderWidth: '2px',
            },
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
        input: {
          '::placeholder': {
            color: theme.palette.mode.dark,
            opacity: 1,
          },
          '&.Mui-disabled': {
            WebkitTextFillColor:
              toggle === 'Dark'
                ? theme.palette.grey[400]
                : theme.palette.mode.dark,
            cursor: 'not-allowed',
            opacity: 1,
          },
          '&:-webkit-autofill': {
            '-webkit-box-shadow':
              toggle === 'Dark'
                ? `0 0 0 100px #111114 inset`
                : `0 0 0 100px #F4F4F4 inset`,
          },
        },
        notchedOutline: {
          border: '1.5px solid rgba(0, 0, 0, 0.06)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
          // color: theme.palette.grey[900],
          color: theme.palette.mode.light,
          fontSize: 18,
          fontWeight: 400,
          [theme.breakpoints.down(600)]: {
            fontSize: 16,
          },
          '&.MuiInput-root:before': {
            border: 'none',
          },
          '&.MuiInput-root:after': {
            border: 'none',
          },
          '&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
            border: 'none',
          },
          input: {
            paddingTop: 0,
            paddingBottom: 0,
            '::placeholder': {
              color: theme.palette.mode.dark,
              opacity: 1,
            },

            '&.Mui-disabled': {
              WebkitTextFillColor:
                toggle === 'Dark'
                  ? theme.palette.grey[400]
                  : theme.palette.mode.dark,
              cursor: 'not-allowed',
            },

            '&:-webkit-autofill': {
              '-webkit-box-shadow':
                toggle === 'Dark'
                  ? `0 0 0 100px #111114 inset`
                  : `0 0 0 100px #F4F4F4 inset`,
              borderRadius: 0,

              WebkitTextFillColor:
                toggle === 'Dark'
                  ? theme.palette.grey[400]
                  : theme.palette.mode.light,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: toggle === 'Dark' ? '#282936' : theme.palette.common.white,
          height: '57px',
          borderRadius: '33px',
          textTransform: 'capitalize',
          letterSpacing: '-0.01em',
          fontSize: '16px',
          fontWeight: 600,
          [theme.breakpoints.down('md')]: {
            height: '52px',
          },
        },
        outlined: {
          borderWidth: '2px',
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          ':hover': {
            borderWidth: '1.5px',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: toggle === 'Dark' && '#53577166',
          marginBottom: '20px',
          boxShadow: '0px 5px 16px 0px rgba(1, 27, 54, 0.06)',
          borderRadius: '20px',
          border: ' 1px solid rgba(0, 0, 0, 0.07)',
          color: '#000000',
          fontSize: 22,
          fontWeight: 500,
          padding: '20px 0',
          transition: 'all 0.3s',
          ':first-of-type': {
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
          ':last-of-type': {
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
          '::before': {
            backgroundColor: 'transparent',
            height: 0,
          },
          '&.Mui-expanded': {
            border: '2px solid #8C7BFF',
            transition: 'all 0.3s',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: '#797E96',
          fontSize: 18,
          fontWeight: 400,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          columnGap: '10px',
          // color: '#C3C7E2',
          color: theme.palette.mode.light,
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        expandIconWrapper: {
          minWidth: '50px ',
          minHeight: '50px ',
          backgroundColor: '#C3C7E2',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid rgba(0, 0, 0, 0.07)',
          boxShadow: '0px 5px 16px 0px rgba(1, 27, 54, 0.06)',
          transform: 'rotate(270deg)',
          transition: 'all 0.3s',
          [theme.breakpoints.down('md')]: {
            minWidth: '35px ',
            minHeight: '35px ',
          },
          svg: {
            path: {
              stroke: theme.palette.primary.main,
            },
          },
          '&.Mui-expanded': {
            backgroundColor: theme.palette.primary.main,
            transform: 'rotate(360deg)',
            transition: 'all 0.3s',
            svg: {
              path: {
                stroke: theme.palette.common.white,
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        user: {
          borderRadius: '70px',
          height: '80px',
          justifyContent: 'center',
          marginLeft: '40px',
          marginRight: '40px',
          width: 'auto !important',
          paddingLeft: '25px',
          paddingRight: '25px',
          boxShadow: '0px 0px 190px 0px rgba(0, 0, 0, 0.07)',
          [theme.breakpoints.down('md')]: {
            marginLeft: '15px',
            marginRight: '15px',
            height: '65px',
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        },
        admin: {
          borderRadius: '20px',
          height: '70px',
          justifyContent: 'center',
          marginLeft: '40px',
          marginRight: '25px',
          width: 'auto',
          paddingLeft: '25px',
          paddingRight: '25px',
          boxShadow: '0px 0px 190px 0px rgba(0, 0, 0, 0.07)',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
          },
          [theme.breakpoints.down('sm')]: {
            marginLeft: '15px',
            marginRight: '15px',
            height: '65px',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
          // border: '1.5px solid rgba(195, 199, 226, 0.25)',
          background:
            toggle === 'Dark' ? theme.palette.mode.lighter : '#E9EBF3',

          boxShadow: '0px 4px 4px 0px #00000040',
          minHeight: '67px',
          minWidth: '197px',
          display: 'flex',
          alignItems: 'center',
        },
        list: {
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '100%',
          justifyContent: 'center',
          minHeight: '40px',
          '&.Mui-disabled': {
            WebkitTextFillColor: toggle === 'Dark' ? '#C4CDD5' : '#535771',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px !important',
          fontWeight: 600,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '40px',
          boxShadow: '0px 0px 190px 0px rgba(0, 0, 0, 0.07)',
          margin: '16px',
          backgroundColor: theme.palette.mode.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '18px',
          background: theme.palette.common.white,
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.17)',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '8px',
          paddingBottom: '0 !important',
        },
      },
    },
    // MuiPickersPopper: {
    //   styleOverrides: {
    //     root: {
    //       top: '50% !important',
    //       left: '50% !important',
    //       transform: 'translate(-50%,-50%) !important',
    //     },
    //   },
    // },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          // display: 'none',
        },
      },
    },
    MuiDateTimePickerToolbar: {
      styleOverrides: {
        separator: {
          display: 'flex',
          alignItems: 'center',
          height: '64px',
          [theme.breakpoints.down('md')]: {
            height: '58px',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: theme.palette.grey[400],
          },
        },
      },
    },
    MuiClockNumber: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: theme.palette.grey[400],
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
          width: '22px !important',
          height: '22px !important',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '10px',
          borderRadius: '10px',
          backgroundColor:
            toggle === 'Dark' ? '#F2F0FF' : theme.palette.mode.dark,
          [theme.breakpoints.down('sm')]: {
            height: '8px',
          },
        },
        bar: {
          borderRadius: '10px',
          backgroundColor:
            toggle === 'Dark' ? '#33FF85' : theme.palette.primary.main,
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: toggle === 'Light' && theme.palette.common.black,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'grey',
            opacity: toggle === 'Light' && 0.7,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          background: 'rgba(140, 123, 255, 0.07)',
          marginTop: '10px',
          fill: theme.palette.primary.main,
          '&.active': {
            background: '#8C7BFF',
            color: 'white',
            fill: 'white',
          },
          ':hover': {
            background: '#8C7BFF',
            color: 'white',
            fill: 'white',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          gap: '10px',
          padding: '11px 10px',
          ':hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          zIndex: 1,
          color: '#797E96',
          fontSize: 16,
          fontWeight: 600,
          maxWidth: 'unset',
          '&.Mui-selected': {
            color: 'white',
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '100%',
          borderRadius: '14px',
        },
        flexContainer: {},
        root: {
          backgroundColor: 'black',
          borderRadius: '16px',
          border: '1px solid rgba(140, 123, 255, 0.14)',
          background: 'rgba(140, 123, 255, 0.20)',
          padding: '4px',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'unset',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSelect-icon': {
            color: 'unset',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: '14px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: 4,
          opacity: 0.7,
          [theme.breakpoints.down('sm')]: {
            top: -2,
          },
          '&.Mui-focused': {
            top: 0,
            opacity: 1,

            [theme.breakpoints.down('sm')]: {
              top: 2,
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        previousNext: {
          border: 'none',
          marginLeft: '16px',
          marginRight: '16px',
        },
        root: {
          fontSize: '8.438px',
          fontWeight: 700,
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            border: '0.469px solid #8C7DFF',
            color: theme.palette.common.white,
          },
        },
        page: {
          color: '#BCBCBC',
          borderRadius: '2.813px',
          border: '0.469px solid #BCBCBC',
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          minWidth: '200px',
          backgroundColor: theme.palette.mode.main,

          [theme.breakpoints.down('sm')]: {
            minWidth: '150px',
          },
          '& .MuiDialogActions-root .MuiButtonBase-root': {
            color: theme.palette.primary.main,
            borderRadius: '12px',
          },
        },
        toolbar: {
          gridColumn: 2,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          boxShadow: ' 0px 8px 8px 0px #00000040',
        },
      },
    },
    MuiTimePickerToolbar: {
      styleOverrides: {
        ampmSelection: {
          flexDirection: 'row',
          gap: '10px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor:
            toggle === 'Dark' ? '#797E96' : theme.palette.primary.main,
          color:
            toggle === 'Dark'
              ? theme.palette.common.black
              : theme.palette.common.white,
          borderRadius: '43px',
          fontSize: 12,
          fontWeight: 700,
          padding: '10px',
        },
        arrow: {
          ':before': {
            backgroundColor:
              toggle === 'Dark' ? '#797E96' : theme.palette.primary.main,
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          '&.MuiInput-input:not([multiple]) option': {
            // backgroundColor: '#161819',
            backgroundColor: '#212229',
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: { backgroundColor: 'transparent', padding: 0 },
        dots: { width: '100%', justifyContent: 'center' },
        dot: { flex: 1, borderRadius: '2px', height: 5 },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      {/* <MUIThemeProvider theme={responsiveFontSizes(theme)}> */}
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
