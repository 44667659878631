import { Box } from '@mui/material'
import React from 'react'
import { ReactComponent as Ellipse1 } from '../resources/images/Ellipse1.svg'
import { ReactComponent as Ellipse2 } from '../resources/images/Ellipse2.svg'
import { ReactComponent as Ellipse3 } from '../resources/images/Ellipse3.svg'
import EllipseAlt1 from '../resources/images/EllipseAlt1.png'
import EllipseAlt2 from '../resources/images/EllipseAlt2.png'
import EllipseAlt3 from '../resources/images/EllipseAlt3.png'
// import { ReactComponent as EllipseAlt1 } from '../resources/images/EllipseAlt1.svg'
// import { ReactComponent as EllipseAlt2 } from '../resources/images/EllipseAlt2.svg'
// import { ReactComponent as EllipseAlt3 } from '../resources/images/EllipseAlt3.svg'

const Background = () => {
  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      height="100vh"
      width="100vw"
      zIndex={-1}
    >
      <Box
        position="relative"
        width="100%"
        height="100%"
        display={{ xs: 'none', sm: 'block' }}
      >
        <Box position="absolute" top={0} left={0}>
          <Ellipse1 />
        </Box>
        <Box position="absolute" top={0} right={0}>
          <Ellipse2 />
        </Box>
        <Box
          position="absolute"
          bottom={0}
          left="50%"
          margin="auto"
          sx={{ transform: 'translateX(-50%)' }}
        >
          <Ellipse3 />
        </Box>
      </Box>
      <Box
        position="relative"
        width="100%"
        height="100%"
        display={{ xs: 'block', sm: 'none' }}
      >
        <Box position="absolute" top={0} left={0}>
          <img src={EllipseAlt1} alt="" />
          {/* <EllipseAlt1 /> */}
        </Box>
        <Box position="absolute" top={'20%'} right={0}>
          <img src={EllipseAlt2} alt="" />
          {/* <EllipseAlt2 /> */}
        </Box>
        <Box position="absolute" top={'30%'} left={0}>
          <img src={EllipseAlt3} alt="" />
          {/* <EllipseAlt3 /> */}
        </Box>
      </Box>
    </Box>
  )
}

export default Background
