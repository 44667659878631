// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material'

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          color: '#1C2A4C !important',
          backgroundColor: '#F4F7FF !important',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '&::-webkit-scrollbar': {
          width: '8px !important',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'rgba(179,179,179,0.1)',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          // backgroundColor: theme.palette.primary.main,
          backgroundColor: 'grey',
          borderRadius: '8px !important',
        },
        // '::selection': {
        //   backgroundColor: 'unset',
        // },
      }}
    />
  )

  return inputGlobalStyles
}
