import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as SubscriptionIcon } from '../resources/images/Subscription_Icon_Dark_Mode.svg'
import { ReactComponent as NotificationIcon } from '../resources/images/Notification_Icon_Dark_Mode.svg'
import { ReactComponent as NotificationAvailableIcon } from '../resources/images/Notification_Available_Icon_Dark_Mode.svg'
import { ReactComponent as NotificationIconLightMode } from '../resources/images/Notification_Icon_Light_Mode.svg'
import { ReactComponent as NotificationAvailableIconLightMode } from '../resources/images/Notification_Available_Icon_Light_Mode.svg'
import { ReactComponent as ExpandMore } from '../resources/images/Expand_More_Dark_Mode_Alt.svg'
import { ReactComponent as LogoutIcon } from '../resources/images/Logout_Icon.svg'
import { ReactComponent as ContactIcon } from '../resources/images/Contact_Us_Icon.svg'
import { ReactComponent as FaqIcon } from '../resources/images/Faq.svg'
import { ReactComponent as SubscriptionIconAlt } from '../resources/images/Subscription_Icon_Alt.svg'
import UserAvatar from '../resources/images/User_Avatar_Dark_Mode.svg'
import UserAvatarLightMode from '../resources/images/User_Avatar_Light_Mode.svg'
import NoNotificationImage from '../resources/images/No_Notification_image_Dark_Mode.svg'
import NoNotificationImageLightMode from '../resources/images/No_Notification_image_Light_Mode.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { get, post } from '../services/Api'
import { LoadingButton } from '@mui/lab'
import logo from '../resources/images/Logo.svg'
import logoLight from '../resources/images/Logo_Light_Mode_Alt.svg'
import { DarkModeSwitch } from 'react-toggle-dark-mode'
import { useThemeContext } from '../hooks/useThemeContext'
import PersonIcon from '@mui/icons-material/Person'

const NavBar = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { dispatch } = useAuthContext()
  const { toggle, toggleFunction } = useThemeContext()

  const [userDetails, setUserDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [anchorElNotification, setAnchorElNotification] = useState(null)
  const [openLogOutConfirmationDialog, setOpenLogOutConfirmationDialog] =
    useState(false)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = async () => {
    const response = await get('celebration-message/notifications-list')
    if (response && response?.data) {
      setNotifications(response?.data)
    }
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget)
  }

  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null)
  }

  const handleOpenLogOutConfirmationDialog = (event) => {
    handleCloseUserMenu()
    setOpenLogOutConfirmationDialog(true)
  }

  const handleCloseLogOutConfirmationDialog = () => {
    setOpenLogOutConfirmationDialog(false)
  }

  const handleLogOut = async () => {
    setIsLoading(true)
    const response = await post('logout')
    setIsLoading(false)
    if (response) {
      localStorage.removeItem('user')
      dispatch({ type: 'LOGOUT' })
    }
  }

  // const navigateToSubscriptionPlansPage = () => {
  //   navigate('/subscription-plans')
  // }

  useEffect(() => {
    const user = localStorage.getItem('user')

    if (user) {
      const data = JSON.parse(user)
      setUserDetails({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
      })
    }
  }, [])

  return (
    <>
      <Container
        // maxWidth="xl"
        sx={{
          paddingX: '0 !important',
          pt: { xs: '20px', md: '40px' },
          maxWidth: { xs: '870px !important', md: '1100px !important' },
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: 'transparent', px: 0, boxShadow: 'none' }}
          variant="user"
        >
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            {/* <Stack display={{ xs: 'flex', md: 'none' }}>
              {notifications?.length > 0 ? (
                <NotificationAvailableIcon
                  cursor="pointer"
                  onClick={handleOpenNotificationMenu}
                />
              ) : (
                <NotificationIcon
                  cursor="pointer"
                  onClick={handleOpenNotificationMenu}
                />
              )}
            </Stack> */}
            <Box
              onClick={() => navigate('/dashboard')}
              sx={{ cursor: 'pointer' }}
            >
              {/* <Typography
                onClick={() => navigate('/dashboard')}
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="h3"
                  display="inline-block"
                  letterSpacing="0.48px"
                  color="primary"
                  fontSize="18px"
                >
                  Happy&nbsp;
                </Typography>
                <Typography
                  variant="h3"
                  display="inline-block"
                  letterSpacing="0.48px"
                  color={theme.palette.yellow.main}
                  fontSize="18px"
                >
                  Bday&nbsp;
                </Typography>
                <Typography
                  variant="h3"
                  display="inline-block"
                  letterSpacing="0.48px"
                  color={theme.palette.green.main}
                  fontSize="18px"
                >
                  Friend
                </Typography>
              </Typography> */}
              <img src={toggle === 'Dark' ? logo : logoLight} alt="" />
            </Box>

            <Stack direction="row" alignItems="center" spacing="12px">
              {/* <Stack display={{ xs: 'none', md: 'flex' }}>
                <SubscriptionIcon
                  cursor="pointer"
                  onClick={navigateToSubscriptionPlansPage}
                />
              </Stack> */}
              {/* <Typography
                component={NavLink}
                to="/blogs"
                color="#C3C7E2"
                variant="h5"
                className="blog"
                display={{ xs: 'none', md: 'block' }}
                sx={{ textDecoration: 'none' }}
              >
                Blog
              </Typography> */}
              {/* <Stack
                alignItems="center"
                justifyContent="center"
                height={40}
                width={40}
                bgcolor="mode.main"
                borderRadius="50%"
              >
                <DarkModeSwitch
                  checked={toggle !== 'Dark'}
                  onChange={toggleFunction}
                  size={22}
                  sunColor="#C3C7E2"
                  moonColor="#535771"
                />
              </Stack> */}
              <Stack>
                {notifications?.length > 0 ? (
                  toggle === 'Dark' ? (
                    <NotificationAvailableIcon
                      cursor="pointer"
                      onClick={handleOpenNotificationMenu}
                    />
                  ) : (
                    <NotificationAvailableIconLightMode
                      cursor="pointer"
                      onClick={handleOpenNotificationMenu}
                    />
                  )
                ) : toggle === 'Dark' ? (
                  <NotificationIcon
                    cursor="pointer"
                    onClick={handleOpenNotificationMenu}
                  />
                ) : (
                  <NotificationIconLightMode
                    cursor="pointer"
                    onClick={handleOpenNotificationMenu}
                  />
                )}
              </Stack>
              <Stack
                direction="row"
                spacing={{ xs: '6px', md: '8px' }}
                alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={handleOpenUserMenu}
                bgcolor="mode.main"
                padding={{ sm: 1 }}
                borderRadius="50px"
              >
                <Avatar
                  src={toggle === 'Dark' ? UserAvatar : UserAvatarLightMode}
                  sx={{ height: '40px', width: '40px' }}
                />
                <Stack
                  // color={theme.palette.common.black}
                  justifyContent="center"
                  spacing="1px"
                  pl="2px"
                  display={{ xs: 'none', sm: 'flex' }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    letterSpacing="-0.01em"
                    color="mode.light"
                  >
                    {userDetails?.name}
                  </Typography>
                  <Typography
                    color="mode.dark"
                    variant="h8"
                    fontWeight={400}
                    letterSpacing="-0.01em"
                  >
                    {userDetails?.email
                      ? userDetails?.email
                      : userDetails?.phone}
                  </Typography>
                </Stack>
                <Stack display={{ xs: 'none', sm: 'flex' }}>
                  <ExpandMore
                    height="24px"
                    width="24px"
                    style={{
                      rotate: Boolean(anchorElUser) && '180deg',
                      transition: 'all 0.3s',
                    }}
                  />
                </Stack>
              </Stack>
              <Menu
                sx={{ mt: { xs: '65px', md: '75px' } }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                autoFocus={false}
              >
                {/* <MenuItem
                  onClick={navigateToSubscriptionPlansPage}
                  sx={{
                    gap: '10px',
                    justifyContent: { xs: 'flex-start', md: 'center' },
                    display: { xs: 'flex', md: 'none' },
                  }}
                >
                  <SubscriptionIconAlt />
                  <Typography
                    textAlign="center"
                    variant="h7"
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        fontSize: '18px',
                      },
                    }}
                  >
                    Subscription Plans
                  </Typography>
                </MenuItem> */}
                {/* <MenuItem
                  onClick={() => navigate('/blogs')}
                  sx={{
                    gap: '10px',
                    justifyContent: { xs: 'flex-start', md: 'center' },
                    display: { xs: 'flex', md: 'none' },
                  }}
                >
                  <Typography
                    textAlign="center"
                    variant="h6"
                    letterSpacing="-0.01em"
                    fontWeight={500}
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        fontSize: '16px',
                      },
                    }}
                  >
                    Blog
                  </Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => navigate('/profile')}
                  sx={{
                    gap: '10px',
                    justifyContent: { xs: 'flex-start' },
                  }}
                >
                  <PersonIcon
                    sx={{ width: 28, height: 28, color: 'rgb(140, 123, 255)' }}
                  />
                  <Typography
                    textAlign="center"
                    variant="h6"
                    fontWeight={500}
                    letterSpacing="-0.01em"
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        fontSize: '16px',
                      },
                    }}
                  >
                    Profile
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate('/contact-us')}
                  sx={{
                    gap: '10px',
                    justifyContent: { xs: 'flex-start' },
                  }}
                >
                  <ContactIcon />
                  <Typography
                    textAlign="center"
                    variant="h6"
                    fontWeight={500}
                    letterSpacing="-0.01em"
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        fontSize: '16px',
                      },
                    }}
                  >
                    Contact us
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate('/faq')}
                  sx={{
                    gap: '10px',
                    justifyContent: { xs: 'flex-start' },
                  }}
                >
                  <FaqIcon />
                  <Typography
                    textAlign="center"
                    variant="h6"
                    fontWeight={500}
                    letterSpacing="-0.01em"
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        fontSize: '16px',
                      },
                    }}
                  >
                    Faq
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleOpenLogOutConfirmationDialog}
                  sx={{
                    gap: '10px',
                    justifyContent: { xs: 'flex-start' },
                  }}
                >
                  <LogoutIcon />
                  <Typography
                    textAlign="center"
                    variant="h6"
                    fontWeight={500}
                    sx={{
                      [theme.breakpoints.down(600)]: {
                        fontSize: '16px',
                      },
                    }}
                  >
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
              <Menu
                sx={{
                  mt: { xs: '60px', md: '53px' },
                }}
                MenuListProps={{
                  sx: {
                    minWidth: '293px',
                    padding: 0,
                    maxHeight: '213px',
                    minHeight: '213px',
                    justifyContent:
                      notifications.length > 0 ? 'flex-start' : 'center',
                  },
                }}
                anchorEl={anchorElNotification}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNotification)}
                onClose={handleCloseNotificationMenu}
                autoFocus={false}
              >
                {notifications.length > 0 ? (
                  <Stack
                    pl="16px"
                    my="16px"
                    mr="11px"
                    sx={{
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '6px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(179,179,179,0.2)',
                        borderRadius: '10px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '10px',
                      },
                    }}
                  >
                    {notifications.map((item, index) => {
                      return (
                        <Box maxWidth="235px">
                          <Typography
                            variant="body1"
                            mt={index !== 0 && '14px'}
                            letterSpacing="0.56px"
                            noWrap
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            mt="4px"
                            mb={notifications.length - 1 !== index && '14px'}
                            lineHeight={1.2}
                            letterSpacing="0.48px"
                            color="main.dark"
                            noWrap
                          >
                            {item.description}
                          </Typography>
                          {notifications.length - 1 !== index && <Divider />}
                        </Box>
                      )
                    })}
                  </Stack>
                ) : (
                  <Stack
                    spacing="15px"
                    py="24px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={
                        toggle === 'Dark'
                          ? NoNotificationImage
                          : NoNotificationImageLightMode
                      }
                      alt=""
                    />
                    <Typography variant="subtitle2">
                      No Notifications Available!
                    </Typography>
                  </Stack>
                )}
              </Menu>
            </Stack>
          </Toolbar>
        </AppBar>
      </Container>
      <Dialog
        open={openLogOutConfirmationDialog}
        onClose={handleCloseLogOutConfirmationDialog}
      >
        <Box padding={{ xs: '20px', md: '36px 30px' }} maxWidth="500px">
          <DialogTitle padding="0 !important" textAlign="center">
            Logout ?
          </DialogTitle>
          <Stack
            spacing="12px"
            mt={{ xs: '8px', md: '14px' }}
            maxWidth="440px"
            px={{ xs: '0', md: '11px' }}
          >
            <Typography variant="h7" textAlign="center">
              Are you sure you want to log out?
            </Typography>
            <Typography variant="h5" textAlign="center" color="mode.dark">
              By logging out, you will be signed out of your account and need to
              re-enter your phone number to access again.
            </Typography>
          </Stack>
          <Stack direction="row" spacing="20px" mt={{ xs: '24px', md: '32px' }}>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleCloseLogOutConfirmationDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              fullWidth
              onClick={handleLogOut}
            >
              {isLoading ? '' : 'Log out'}
            </LoadingButton>
          </Stack>
        </Box>
      </Dialog>
    </>
  )
}

export default NavBar
