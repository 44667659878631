import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { SnackbarProvider } from 'notistack'
import ScrollToTop from './components/ScrollToTop'
import { Suspense, useEffect } from 'react'
import ThemeProvider from './themeWeb'
import styled from '@emotion/styled'
import { AuthContextProvider } from './context/AuthContext'
import { AdminAuthContextProvider } from './context/AdminAuthContext'
import './App.css'
import SuspenseLoader from './components/SuspenseLoader'
import { useThemeContext } from './hooks/useThemeContext'

const App = () => {
  const { toggle } = useThemeContext()

  const StyledSnackbarProvider = styled(SnackbarProvider)`
    &.notistack-MuiContent-success {
      background-color: #8c7bff;
      color: ${toggle === 'Dark' ? '#282936' : '#FFFFFF'};
    }
    ,
    &.notistack-MuiContent {
      box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
        0px 5px 3px -2px rgba(0, 0, 0, 0.02);
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  `

  useEffect(() => {
    const request = window.indexedDB.open('happyBday')

    request.onerror = function (event) {
      console.error('Error opening IndexedDB database')
    }

    request.onupgradeneeded = async (event) => {
      const db = event.target.result
      await db.createObjectStore('celebration')
    }
  }, [])

  return (
    <BrowserRouter>
      <ThemeProvider>
        <ScrollToTop />
        <StyledSnackbarProvider
          hideIconVariant
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Suspense fallback={<SuspenseLoader />}>
            <AuthContextProvider>
              <AdminAuthContextProvider>
                <Routes />
              </AdminAuthContextProvider>
            </AuthContextProvider>
          </Suspense>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
