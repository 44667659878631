import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Background from '../../components/Background'
import NavBar from '../../components/NavBar'
import { useAuthContext } from '../../hooks/useAuthContext'
import { ReactComponent as DownIcon } from '../../resources/images/Down_Arrow.svg'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
  useTheme,
} from '@mui/material'
import { get } from '../../services/Api'
import containerBackground from '../../resources/images/Container_Background.png'
import containerBackgroundLightMode from '../../resources/images/Container_Background_Light_Mode_Alt.png'
import { useThemeContext } from '../../hooks/useThemeContext'

const Faq = () => {
  const theme = useTheme()
  const { user } = useAuthContext()
  const [expanded, setExpanded] = React.useState('panel1')
  const { toggle } = useThemeContext()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    get('manage-count')
  }, [])
  return (
    <>
      <Helmet>
        <title>Faq | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      {/* <Background /> */}
      {user ? <NavBar /> : ''}
      <Container
        maxWidth="xl"
        sx={{
          paddingX: '0 !important',
        }}
      >
        <Box pb={{ xs: '15px', md: '30px' }}>
          <Box
            minHeight={{
              xs: 'calc(100vh - 85px - 15px - 15px)',
              md: 'calc(100vh - 120px - 30px - 30px)',
            }}
            borderRadius="40px"
            boxShadow={
              toggle === 'Dark'
                ? '0px 0px 190px 0px rgba(0, 0, 0, 0.07)'
                : '0px 8px 20px 0px #0000001A'
            }
            sx={{
              mt: { xs: '15px', md: '30px' },
              mx: { xs: '15px', md: '40px' },

              background: `url(${
                toggle === 'Dark'
                  ? containerBackground
                  : containerBackgroundLightMode
              })`,
              backgroundPosition: 'center',
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
            }}
          >
            <Box px={{ xs: '20px', md: '100px' }} py="40px">
              <Typography
                variant="h2"
                textAlign="center"
                px="50px"
                fontWeight={600}
              >
                Frequently Asked Questions
              </Typography>

              <Box mt="50px">
                <Accordion
                  defaultExpanded
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Is HappyBdayFriend free to use?
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6">
                      Yes! All users can create a celebration page for free. To
                      collect more than 5 videos, you can upgrade to our premium
                      plan
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      What is the video size limit for uploads?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Each video can be up to 30 MB in size.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Does HappyBdayFriend put the birthday videos together?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      We are working towards offering video compilation in the
                      future. Currently, our focus is on making it easy for you
                      to collect birthday videos.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      How long does it take to set up a celebration page?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Setting up a page is quick and easy – it takes less than a
                      minute!
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      How can I share the celebration page?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Simply copy the page link and share it with friends and
                      family for them to submit their video wishes.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel6-content"
                    id="panel6-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Until when can people submit videos?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Videos can be submitted up until 24 hours after the
                      specified deadline.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel7'}
                  onChange={handleChange('panel7')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel7-content"
                    id="panel7-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      When do videos on the celebration page expire?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Videos on the celebration page expire after 15 days.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel8'}
                  onChange={handleChange('panel8')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel8-content"
                    id="panel8-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      How do I download the videos from the celebration page?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Log in from a laptop or desktop and select "Download All"
                      videos. A pop-up will appear to confirm; select 'Yes' to
                      proceed with downloads.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel9'}
                  onChange={handleChange('panel9')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel9-content"
                    id="panel9-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Are there any limitations on who can send video messages?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      No, anyone you share the celebration page link with can
                      upload a video message, regardless of where they are in
                      the world.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel10'}
                  onChange={handleChange('panel10')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel9-content"
                    id="panel9-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Is there a way to preview the celebration page before
                      sharing it?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Yes, the creator of the celebration page can preview your
                      celebration page to see how it looks before sending it out
                      to friends and family.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel11'}
                  onChange={handleChange('panel11')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel10-content"
                    id="panel10-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Is it possible to extend the deadline for submitting
                      videos?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      Yes, you can adjust the deadline from your celebration
                      page settings to allow more time for video submissions as
                      long as it’s before the deadline.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel12'}
                  onChange={handleChange('panel12')}
                >
                  <AccordionSummary
                    expandIcon={<DownIcon />}
                    aria-controls="panel11-content"
                    id="panel11-header"
                  >
                    <Typography variant="h7" fontWeight={500}>
                      Can I use this app for occasions other than birthdays?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6">
                      You're welcome to use it for any celebration where
                      collecting video messages is desired.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
          <Typography
            textAlign="center"
            mt="15px"
            fontSize={14}
            fontWeight={400}
            color="mode.darker"
          >
            ©2024 HappyBdayFriend{' '}
          </Typography>
        </Box>
      </Container>
    </>
  )
}

export default Faq
