import { Box, useTheme } from '@mui/material'
import React from 'react'

const SuspenseLoader = () => {
  const theme = useTheme()
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{ transform: 'translate(-50%,-50%)' }}
    >
      <Box
        sx={{
          width: '48px',
          height: '48px',
          margin: 'auto',
          position: 'relative',
          ':before': {
            content: "''",
            width: '48px',
            height: '5px',
            background: theme.palette.primary.light,
            opacity: '0.25',
            position: 'absolute',
            top: '60px',
            left: '0',
            borderRadius: '50%',
            animation: 'shadow 0.5s linear infinite',
          },
          ':after': {
            content: "''",
            width: '100%',
            height: '100%',
            background: theme.palette.primary.main,
            animation: 'bxSpin 0.5s linear infinite',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '4px',
          },
          '@keyframes bxSpin ': {
            '17%': {
              borderBottomRightRadius: '3px',
            },
            '25%': {
              transform: 'translateY(9px) rotate(22.5deg)',
            },
            '50%': {
              transform: 'translateY(18px) scale(1, .9) rotate(45deg)',
              borderBottomRightRadius: '40px',
            },
            '75%': {
              transform: 'translateY(9px) rotate(67.5deg)',
            },
            '100%': {
              transform: 'translateY(0) rotate(90deg)',
            },
          },
          '@keyframes shadow': {
            '0%, 100%': {
              transform: 'scale(1, 1)',
            },
            '50%': {
              transform: 'scale(1.2, 1)',
            },
          },
        }}
      />
    </Box>
  )
}

export default SuspenseLoader
